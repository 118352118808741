@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;700&display=swap");

@layer base {
	html,
	body,
	#root {
		height: 100%;
		width: 100%;
	}
}

@layer components {
	.title {
		position: relative;
		font-family: Raleway;
		font-size: 25px;
		font-weight: 800;
		letter-spacing: 0.6px;
		text-align: center;
		text-wrap: pretty;
		text-transform: uppercase;
		@apply text-sky-900;
		@apply dark:text-amber-500;
	}

	.title::after {
		font-family: Raleway;
		font-size: 30px;
		position: absolute;
		content: "/";
		@apply text-teal-400;
	}

	.description {
		font-family: Roboto Condensed;
		font-size: 15px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 0.6px;
		text-wrap: pretty;
		overflow-wrap: break-word;
	}

	@media (min-width: 1025px) {
		.description {
			margin: 0;
		}
	}

	@media (min-width: 768px) {
		.title {
			font-size: 45px;
			margin: 0;
		}

		.title::after {
			font-size: 50px;
		}
	}
}
